<template>
  <div class="base-form">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
      status-icon
    >
      <el-form-item label="方案名称：" prop="name">
        <div class="fa-name">
          <el-input size="small" v-model="ruleForm.name" />
        </div>
      </el-form-item>
      <el-form-item label="计算方式：" prop="calculationMethod">
        <el-radio-group v-model="ruleForm.calculationMethod">
          <el-radio :label="1">单笔计算</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="关联人员">
        <div class="staff">
          <div>
            <el-button @click="handleGuanlianClick" plain size="small">请选择</el-button>
          </div>
          <div class="opt-name" v-if="currentOptRoleName&&currentOptRoleName.length">
            已选:<template :key="roleitem" v-for="roleitem in currentOptRoleName">
              <el-tag size="small">{{ roleitem }}</el-tag>
            </template>
          </div>
        </div>
      </el-form-item>

      <el-form-item v-if="ruleKind == 1" label="服务规则配置：">
        <div style="margin-bottom: 20px">
            <el-button @click="handleTreeClick" plain size="small">请选择</el-button>
        </div>
        <div>
          <el-table
            :data="tableData"
            style="width: 1200px"
            :span-method="objectSpanMethod"
            border
          >
            <el-table-column align="center" prop="kindName" label="品类" width="150"/>
            <el-table-column align="center" prop="serveName" label="项目"/>
            <el-table-column align="center" prop="payWay" label="支付方式" width="200">
              <template #default="scope">
                <div v-for="rows in scope.row.itemList" class="borders" :key="rows">
                  <el-select
                    size="small"
                    v-model="rows.payWay"
                    class="m-2"
                    placeholder="支付方式"
                  >
                    <el-option
                      v-for="item in payCategory"
                      :key="item.payWay"
                      :label="item.payWayName"
                      :value="item.payWay"
                    />
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="type" label="提成类型" width="200">
              <template #default="scope">
                <div v-for="rows in scope.row.itemList" class="borders" :key="rows">
                  <el-select
                    size="small"
                    v-model="rows.type"
                    class="m-2"
                    placeholder="提成类型"
                    @change="changeType($event,scope.row)"
                  >
                    <el-option
                      v-for="item in tichengType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="numericalValue" label="提成金额" width="200">
              <template #default="scope">
                <div v-for="rows in scope.row.itemList" class="borders" :key="rows">
                  <el-input
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    size="small"
                    v-model="rows.numericalValue"
                    placeholder="提成金额"
                  >
                    <template #suffix>
                      <template v-if="rows.type == 2"> | % </template>
                      <template v-else> | 元 </template>
                    </template>
                  </el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="100">
              <template #default="scope">
                <div v-for="(rows,index) in scope.row.itemList" class="borders" :key="rows">
                  <el-button @click="addNewWay(scope)" size="small" v-if="index==0">+</el-button>
                  <el-button @click="removeWay(scope.$index,index)" size="small" type="danger" v-else>-</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
      <el-form-item v-if="ruleKind == 2" label="卡项规则配置：">
        <div style="margin-bottom: 20px">
            <el-button @click="handleTreeClick" plain size="small">请选择</el-button>
        </div>
        <div>
          <el-table
            :data="tableData"
            style="width: 1000px"
            :span-method="objectSpanMethod"
            border
          >
            <el-table-column align="center" prop="kindName" label="品类" width="150"/>
            <el-table-column align="center" prop="name" label="项目"/>
            <el-table-column align="center" prop="payWay" label="支付方式" width="200">
              <template #default="scope">
                <div v-for="rows in scope.row.itemList" class="borders" :key="rows">
                  <el-select
                    size="small"
                    v-model="rows.payWay"
                    class="m-2"
                    placeholder="支付方式"
                  >
                    <el-option
                      v-for="item in payCategory"
                      :key="item.payWay"
                      :label="item.payWayName"
                      :value="item.payWay"
                    />
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="type" label="提成类型" width="200">
              <template #default="scope">
                <div v-for="rows in scope.row.itemList" class="borders" :key="rows">
                  <el-select
                    size="small"
                    v-model="rows.type"
                    class="m-2"
                    placeholder="提成类型"
                    @change="changeType($event,scope.row)"
                  >
                    <el-option
                      v-for="item in tichengType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="numericalValue" label="提成金额" width="200">
              <template #default="scope">
                <div v-for="rows in scope.row.itemList" class="borders" :key="rows">
                  <el-input
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    size="small"
                    v-model="rows.numericalValue"
                    placeholder="提成金额"
                  >
                    <template #suffix>
                      <template v-if="rows.type == 2"> | % </template>
                      <template v-else> | 元 </template>
                    </template>
                  </el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="100">
              <template #default="scope">
                <div v-for="(rows,index) in scope.row.itemList" class="borders" :key="rows">
                  <el-button @click="addNewWay(scope)" size="small" v-if="index==0">+</el-button>
                  <el-button @click="removeWay(scope.$index,index)" size="small" type="danger" v-else>-</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>

      <el-form-item v-if="$route.query.isview == 'false'">
        <div class="save-btn">
          <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
    <div v-if="$route.query.isview == 'true'" class="mask"></div>
    <!-- 关联人员 -->
    <rankDistributeDialog
      @checkedChange="handleRankCheckedChange"
      @changeDialogVisible="rankChangeDialogVisible"
      :dialogVisible="rankDialogVisible"
      :chooseList="ruleForm.correlationIds"
    >
    </rankDistributeDialog>
    <!-- 服务/卡项树形 -->
    <treeKindDialog v-if="treeDialogVisible" @checkedChange="handleTreeCheckedChange" :dialogVisible="treeDialogVisible" :data="serveCategoryList" @changeDialogVisible="treeChangeDialogVisible" :chooseList="checkedTreeList"></treeKindDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { rules } from '../../config'
import { getNotBindRuleEmp,getCardTreeList } from '@/service/main/commission'
import { COMPANY_ID } from '@/common/constant'
import { tichengType } from '../../config'
import distribute from '../distribute-dialog/distribute-dialog'
import cardDistributeDialog from '../card-distribute-dialog/card-distribute-dialog.vue'
import { useRoute } from 'vue-router'
import rankDistributeDialog from '../rank-distribute-dialog/rank-distribute-dialog.vue'
import { addTichengRule, getTcDetail, updateTcRule} from '@/service/main/score'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { getShopList } from '@/service/main/base'
import { getTeacherRankServeList} from '@/service/main/reserve'
import { throttle } from '@/utils/debounce'
import treeKindDialog from '../tree-kind-dialog/tree-kind-dialog.vue'
import {getAllPayWay} from '@/service/main/order'


export default defineComponent({
  props: {

  },
  components: {
    distribute,
    cardDistributeDialog,
    rankDistributeDialog,
    treeKindDialog
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const ruleKind = computed(() => route.query.type)
    const ruleFormRef = ref()
    const editId = computed(() => route.query.ruleId)

    const ruleForm = reactive({
      name: "",
      calculationMethod: 1,
      correlationIds: [],
      itemList:[],
      type: route.query.type
    })
    const payCategory = ref([])

    const currentOptRoleName = ref([])
    const handleRankCheckedChange = obj => {
      let { ids, optNameArr } = obj
      ruleForm.correlationIds = ids
      currentOptRoleName.value = optNameArr
    }

    const serveCategoryList = ref([])

    const success = () => {
      router.push({
        path: "/main/score/scoreRuleList"
      })
      ElMessage({
        message: '操作成功!',
        type: 'success',
      })
    }

    //插入一条该项目的提成规则配置
    const addNewWay = (item) => {
      let index = item.$index
      let row = item.row
      if (row.itemList&&row.itemList.length) {
        let type = row.itemList[0].type
        tableData.value[index].itemList.push({type,payWay:'OTHER'})
      }else{
        tableData.value[index].itemList.push({payWay:'OTHER'})
      }
    }
    //删除该项配置
    const removeWay = (rowIndex,index) => {
      tableData.value[rowIndex].itemList.splice(index, 1);
    }
    const handleData = () =>{
      checkedTreeList.value = tableData.value.map(item=>{
        return item.id
      })
      tableData.value.map(item=>{
        serveCategoryList.value.map(items=>{
          items.infos.map(items1=>{
            if (item.id == items1.id) {
              items1.itemList=item.itemList
            }
          })
        })
      })
    }
    const objectSpanMethod = ({
      row,
      column,
      rowIndex,
      columnIndex,
    }) => {
      if (columnIndex === 0) {
        const _row = oneRow()[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    }
    const oneRow = () =>{
      const spanOne = []
      let pos = 0
      tableData.value.forEach((ele, i) => {
        if (i === 0) {
          spanOne.push(1)
          pos = 0
        } else {
          if (ele.kindName === tableData.value[i - 1].kindName ) {
            spanOne[pos] += 1
            spanOne.push(0)
          } else {
            spanOne.push(1)
            pos = i
          }
        }
      })
      return [...spanOne]
    }

    const handleSaveClick = throttle (async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let { type, ruleId } = route.query

          // 判断是否选人
          if (currentOptRoleName.value.length == 0) {
            ElMessage({
              message: '请选择关联人员',
              type: 'warning',
            })
            return
          }

          const serveFilter = (arr) => {
            let array = []
            let filterArray =[]
            arr.map(item=>{
              array.push({itemId:item.serveId,itemName:item.serveName,kindName:item.kindName,itemList:item.itemList})
            })
            array.map(item=>{
              item.itemList=item.itemList.filter(i=>{
                return i.type&&(i.numericalValue||i.numericalValue==0)&&i.payWay
              })
            })
            filterArray=array.filter(item=>{
              return item.itemList&&item.itemList.length
            })
            return filterArray
          }
          const cardFilter = (arr) => {
            let array = []
            let filterArray =[]
            arr.map(item=>{
              array.push({itemId:item.id,itemName:item.name,kindName:item.kindName,itemList:item.itemList})
            })
            array.map(item=>{
              item.itemList=item.itemList.filter(i=>{
                return i.type&&(i.numericalValue||i.numericalValue==0)&&i.payWay
              })
            })
            filterArray=array.filter(item=>{
              return item.itemList&&item.itemList.length
            })
            return filterArray
          }

          let sitArr = []
          if (type == 1) {
            sitArr = serveFilter(tableData.value)
          } else if (type == 2) {
            sitArr = cardFilter(tableData.value)
          }

          if (sitArr.length == 0) {
            ElMessage({
              message: '最少设置一个分类规则！',
              type: 'warning',
            })
            return
          }

          /*
            type
            1服务提成
            2卡项提成
          */

          let res
          // 判断是新增还是编辑 有ruleId是编辑 没有是新增
          // 新增
          if (!ruleId) {
            res = await addTichengRule({ ...ruleForm,itemList:sitArr })
            if (res.code == 0) {
              success()
            }
          }
          // 编辑
          else {
            const res = await updateTcRule({ ...ruleForm, id: route.query.ruleId,itemList:sitArr })
            if (res.code == 0) {
              success()
            }
          }
          console.log('submit!')
        } else {
          console.log('error submit!', fields)
        }
      })
    },1000)


    const rankDialogVisible = ref(false)
    const rankChangeDialogVisible = flag => {
      rankDialogVisible.value = flag
    }

    const handleGuanlianClick = () => {
      rankDialogVisible.value = true
    }

    const treeDialogVisible = ref(false)
    const treeChangeDialogVisible = flag => {
      treeDialogVisible.value = flag
    }

    const handleTreeClick = () => {
      handleData()
      treeDialogVisible.value = true
    }
    const tableData = ref([])
    const checkedTreeList = ref([])
    const handleTreeCheckedChange = obj => {
      tableData.value = obj.map(item=>{
        return {...item,itemList:item.itemList?item.itemList:[{payWay:'OTHER'}]}
      })
      checkedTreeList.value = obj.map(item=>{
        return item.id
      })
    }
    const changeType = (e,row) =>{
      row.itemList.forEach(item=>{
        item.type=e
      })
    }

    const allShopBind = ref([])
    const shopList = ref([])
    const initPage = async () => {
      let data = await getAllPayWay()
      const sres = await getShopList(COMPANY_ID)
      shopList.value = sres.data.list
      payCategory.value = data.data

      let ruleId = route.query.ruleId
      let ruleType = route.query.type
      if (ruleType == 1) {
        const res = await getTeacherRankServeList({})
        res.data.map(item=>{
          item.id = item.kindId
          item.label = item.kindName
          item.infos.map(items=>{
            items.id = items.serveId
            items.label = items.serveName
            items.kindName = item.kindName
          })
        })
        serveCategoryList.value = res.data
      }else if (ruleType == 2) {
        const res = await getCardTreeList()
        res.data.map(item=>{
          item.id = item.kindId
          item.label = item.kindName
          item.infos.map(items=>{
            items.label = items.name
            items.kindName = item.kindName
          })
        })
        serveCategoryList.value = res.data
      }

      // 判断是编辑还是新增 有ruleId是编辑，否则是新增
      // 编辑
      if (ruleId) {
        let type = route.query.type
        let optDataList = await getNotBindRuleEmp({ruleId,type})
        const detRes = await getTcDetail(ruleId)
        let detail = detRes.data
        let { calculationMethod, correlationIds, name, itemList} = detail
        ruleForm.name = name
        ruleForm.calculationMethod = calculationMethod
        ruleForm.correlationIds = correlationIds

        let array = []
        optDataList.data.map(item=>{
          array=[...array,...item.list]
        })
        let nameList = []
        correlationIds.map(items=>{
          array.map(item=>{
            if (items==item.id) {
              nameList.push(item.name)
            }
          })
        })
        const uniqueArr = Array.from(new Set(nameList.map(JSON.stringify)), JSON.parse);
        currentOptRoleName.value=uniqueArr
        if (type==1) {
          itemList.map(item=>{
            item.id=item.itemId
            item.serveId=item.itemId
            item.serveName=item.itemName
          })
        } else {
          itemList.map(item=>{
            item.id=item.itemId
            item.serveId=item.itemId
            item.name=item.itemName
          })
        }
        tableData.value=itemList
      }
    }
    initPage()

    return {
      shopList,
      allShopBind,
      ruleFormRef,
      handleSaveClick,
      currentOptRoleName,
      handleRankCheckedChange,
      handleGuanlianClick,
      rankChangeDialogVisible,
      rankDialogVisible,
      ruleKind,
      ruleForm,
      rules,
      serveCategoryList,
      tichengType,
      editId,
      treeDialogVisible,
      treeChangeDialogVisible,
      handleTreeClick,
      handleTreeCheckedChange,
      tableData,
      payCategory,
      checkedTreeList,
      addNewWay,
      removeWay,
      objectSpanMethod,
      changeType
    }

  }
})
</script>

<style scoped lang="less">
.base-form {
  position: relative;

  .mask {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  .opt-name {
    margin-left: 12px;
    font-size: 12px;

    &:deep(.el-tag) {
      margin-left: 10px;
    }
  }

  .jd-peizhi-value {
    max-width: 700px;

    .tips {
      color: #f56c6c;
      margin-top: 10px;
    }

    .titles {
      display: flex;
      height: 36px;
      line-height: 36px;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 14px;
      padding: 0 18px;

      .num {
        flex: 0.6;
      }

      .qujian {
        flex: 2;
      }

      .type {
        flex: 1.2;
      }

      .price {
        flex: 1.2;
      }

      .opt {
        flex: 0.8;
      }
    }
  }

  .peizhi-value {
    max-width: 700px;

    &:deep(.el-select) {
      max-width: 120px;
    }

    &:deep(.el-input) {
      max-width: 120px;
    }

    .peizhi-list {
      .tips {
        color: #f56c6c;
        font-size: 12px;
        margin-top: 10px;
      }

      .item {
        display: flex;
        line-height: 60px;
        height: 60px;
        border-bottom: 1px solid rgba(204, 204, 204, 0.6);
        padding: 0 18px;

        .num {
          flex: 0.6;
        }

        & > div {
          flex: 1;
        }

        .price {
          flex: 1.4;
        }
      }
    }

    .titles {
      display: flex;
      height: 36px;
      line-height: 36px;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 14px;
      padding: 0 18px;

      .num {
        flex: 0.6;
      }

      .price {
        flex: 1.4;
      }

      & > div {
        flex: 1;
      }
    }
  }

  .staff {
    display: flex;

    .opt {
      margin-left: 10px;
    }
  }

  .fa-name {
    &:deep(.el-input) {
      max-width: 260px;
    }
  }
  &:deep(.el-table) {
    .cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .borders{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: 1px solid #ebeef5;
    align-items: center;
    // height: 60px;
    // line-height: 60px;
    padding: 12px 10px !important;
    .el-form-item{
      margin-bottom: 0 !important;
    }
  }
  .borders:last-child{
      border: none !important;
  }
}
</style>
