export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '提成设置',
    path: ''
  },
  {
    name: '方案列表',
    path: ''
  }
]

export const addBreadcrumbList1 = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '新增服务提成规则',
    path: ''
  }
]

export const addBreadcrumbList2 = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '新增卡项提成规则',
    path: ''
  }
]

export const addBreadcrumbList4 = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '新增门店提成规则',
    path: ''
  }
]


export const detailBreadcrumbList1 = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '服务提成详情',
    path: ''
  }
]

export const detailBreadcrumbList2 = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '卡项提成详情',
    path: ''
  }
]

export const detailBreadcrumbList4 = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '门店提成详情',
    path: ''
  }
]




export const updateBreadcrumbList1 = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '更新服务提成方案',
    path: ''
  }
]

export const updateBreadcrumbList2 = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '更新卡项提成方案',
    path: ''
  }
]

export const updateBreadcrumbList4 = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '更新门店提成方案',
    path: ''
  }
]


export const rules = {
  name: [{ required: true, message: '输入方案名称', trigger: 'blur' }],
  calculationMethod:[{ required: true, message: '选择计算方式', trigger: 'blur' }],

}

export const tichengType = [
  {
    label: '固定提成',
    value: 1
  },
  {
    label: '比例提成',
    value: 2
  }
]
export const RepurchaseType = [
  {
    label: '复购',
    value: 1
  },
  {
    label: '非复购',
    value: 2
  }
]
