<template>
  <div class="base-form">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
      status-icon
    >
      <el-form-item label="方案名称：" prop="name">
        <div class="fa-name">
          <el-input size="small" v-model="ruleForm.name" />
        </div>
      </el-form-item>

      <el-form-item label="选择门店" prop="correlationIds">
        <el-select
          size="small"
          clearable
          multiple
          v-model="ruleForm.correlationIds"
          class="m-2"
          placeholder="选择门店"
        >
          <el-option
            v-for="item in shopList"
            :key="item.shopId"
            :label="item.shopName"
            :value="item.shopId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleKind == 1" label="服务规则配置：">
        <div>
          <el-table
            :data="serveCategoryList"
            style="width: 100%"
            row-key="kindId"
            :tree-props="{ children: 'infos', hasChildren: 'hasChildren' }"
            :default-expand-all="$route.query.isview=='true'"
          >
            <el-table-column width="50" />
            <el-table-column prop="kindName" label="品类" width="150">
              <template #default="scope">
                <p>{{ scope.row.infos?scope.row.kindName:'-' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="项目" width="200">
              <template #default="scope">
                <p>{{ scope.row.infos?'-':scope.row.serveName }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="statisticalType" label="复购" width="200" v-if="ruleKind == 1">
              <template #default="scope" v-if="editId">
                  <el-select v-if="!scope.row.infos"
                    size="small"
                    v-model="scope.row.statisticalType"
                    class="m-2"
                    placeholder="复购类型"
                  >
                    <el-option
                      v-for="item in RepurchaseType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <p v-else>-</p>
              </template>
              <template #default="scope" v-else>
                <el-select v-if="scope.row.infos"
                    @change="handleRepurchaseChange($event, scope.row,scope.row.kindId)"
                    size="small"
                    v-model="scope.row.statisticalType"
                    class="m-2"
                    placeholder="复购类型"
                  >
                    <el-option
                      v-for="item in RepurchaseType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <el-select v-else
                    size="small"
                    v-model="scope.row.statisticalType"
                    class="m-2"
                    placeholder="复购类型"
                  >
                    <el-option
                      v-for="item in RepurchaseType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="提成类型" width="200">
              <template #default="scope" v-if="editId">
                  <el-select v-if="!scope.row.infos"
                    size="small"
                    v-model="scope.row.type"
                    class="m-2"
                    placeholder="提成类型"
                  >
                    <el-option
                      v-for="item in tichengType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <p v-else>-</p>
              </template>
              <template #default="scope" v-else>
                <el-select v-if="scope.row.infos"
                    @change="handleTypeChange($event, scope.row,scope.row.kindId)"
                    size="small"
                    v-model="scope.row.type"
                    class="m-2"
                    placeholder="提成类型"
                  >
                    <el-option
                      v-for="item in tichengType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <el-select v-else
                    size="small"
                    v-model="scope.row.type"
                    class="m-2"
                    placeholder="提成类型"
                  >
                    <el-option
                      v-for="item in tichengType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="numericalValue" label="提成金额" width="200">
              <template #default="scope" v-if="editId">
                <el-input v-if="!scope.row.infos"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  size="small"
                  v-model="scope.row.numericalValue"
                  placeholder="提成金额"
                >
                  <template #suffix>
                    <template v-if="scope.row.type == 2"> | % </template>
                    <template v-else> | 元 </template>
                  </template>
                </el-input>
                <p v-else>-</p>
              </template>
              <template #default="scope" v-else>
                <el-input v-if="scope.row.infos"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  @change="handlePriceChange($event, scope.row,scope.row.kindId)"
                  size="small"
                  v-model="scope.row.numericalValue"
                  placeholder="提成金额"
                >
                  <template #suffix>
                    <template v-if="scope.row.type == 2"> | % </template>
                    <template v-else> | 元 </template>
                  </template>
                </el-input>
                <el-input v-else
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  size="small"
                  v-model="scope.row.numericalValue"
                  placeholder="提成金额"
                >
                  <template #suffix>
                    <template v-if="scope.row.type == 2"> | % </template>
                    <template v-else> | 元 </template>
                  </template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
      <el-form-item v-if="ruleKind == 2" label="卡项规则配置：">
        <div>
          <el-table
            :data="serveCategoryList"
            style="width: 100%"
            row-key="kindId"
            :tree-props="{ children: 'infos', hasChildren: 'hasChildren' }"
            :default-expand-all="$route.query.isview=='true'"
          >
            <el-table-column width="50" />
            <el-table-column prop="kindName" label="品类" width="150">
              <template #default="scope">
                <p>{{ scope.row.infos?scope.row.kindName:'-' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="项目" width="200">
              <template #default="scope">
                <p>{{ scope.row.infos?'-':scope.row.name }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="提成类型" width="200">
              <template #default="scope" v-if="editId">
                  <el-select v-if="!scope.row.infos"
                    size="small"
                    v-model="scope.row.type"
                    class="m-2"
                    placeholder="提成类型"
                  >
                    <el-option
                      v-for="item in tichengType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <p v-else>-</p>
              </template>
              <template #default="scope" v-else>
                <el-select v-if="scope.row.infos"
                    @change="handleTypeChange($event, scope.row,scope.row.kindId)"
                    size="small"
                    v-model="scope.row.type"
                    class="m-2"
                    placeholder="提成类型"
                  >
                    <el-option
                      v-for="item in tichengType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <el-select v-else
                    size="small"
                    v-model="scope.row.type"
                    class="m-2"
                    placeholder="提成类型"
                  >
                    <el-option
                      v-for="item in tichengType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="numericalValue" label="提成金额" width="200">
              <template #default="scope" v-if="editId">
                <el-input v-if="!scope.row.infos"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  size="small"
                  v-model="scope.row.numericalValue"
                  placeholder="提成金额"
                >
                  <template #suffix>
                    <template v-if="scope.row.type == 2"> | % </template>
                    <template v-else> | 元 </template>
                  </template>
                </el-input>
                <p v-else>-</p>
              </template>
              <template #default="scope" v-else>
                <el-input v-if="scope.row.infos"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  @change="handlePriceChange($event, scope.row,scope.row.kindId)"
                  size="small"
                  v-model="scope.row.numericalValue"
                  placeholder="提成金额"
                >
                  <template #suffix>
                    <template v-if="scope.row.type == 2"> | % </template>
                    <template v-else> | 元 </template>
                  </template>
                </el-input>
                <el-input v-else
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  size="small"
                  v-model="scope.row.numericalValue"
                  placeholder="提成金额"
                >
                  <template #suffix>
                    <template v-if="scope.row.type == 2"> | % </template>
                    <template v-else> | 元 </template>
                  </template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>

      <el-form-item v-if="$route.query.isview == 'false'">
        <div class="save-btn">
          <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
    <div v-if="$route.query.isview == 'true'" class="mask"></div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { rules } from '../../config'
import { getCardTreeList,getUnBindRuleShopList } from '@/service/main/commission'
import { COMPANY_ID } from '@/common/constant'
import { tichengType,RepurchaseType } from '../../config'
import distribute from '../distribute-dialog/distribute-dialog'
import { useRoute } from 'vue-router'
import { createShopCommissionRule, getShopCommissionRule, editShopCommissionRule} from '@/service/main/score'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { getTeacherRankServeList} from '@/service/main/reserve'
import { throttle } from '@/utils/debounce'

export default defineComponent({
  props: {

  },
  components: {
    distribute,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const ruleKind = computed(() => route.query.ruleKind)
    const ruleFormRef = ref()
    const editId = computed(() => route.query.ruleId)

    const ruleForm = reactive({
      name: "",
      correlationIds: [],
      itemList:[],
      type: route.query.ruleKind
    })

    const serveCategoryList = ref([])

    const success = () => {
      router.push({
        path: "/main/score/scoreRuleList"
      })
      ElMessage({
        message: '操作成功!',
        type: 'success',
      })
    }

    const handleTypeChange = (e,row,id) => {
      var index=serveCategoryList.value.findIndex(function (value,index,arr1) {
          return value.kindId==id
      });
      let array = row.infos.map(item=>{
        return {...item,type:e}
      })
      serveCategoryList.value[index].infos=array
    }

    const handlePriceChange = (e,row,id) => {
      var index=serveCategoryList.value.findIndex(function (value,index,arr1) {
          return value.kindId==id
      });
      let array = row.infos.map(item=>{
        return {...item,numericalValue:e}
      })
      serveCategoryList.value[index].infos=array
    }
    
    const handleRepurchaseChange = (e,row,id) => {
      var index=serveCategoryList.value.findIndex(function (value,index,arr1) {
          return value.kindId==id
      });
      let array = row.infos.map(item=>{
        return {...item,statisticalType:e}
      })
      serveCategoryList.value[index].infos=array
    }

    const handleSaveClick = throttle (async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let { ruleKind, ruleId } = route.query

          const serveFilter = (arr) => {
            let array = []
            let filterArray =[]
            arr.map(item=>{
              item.infos.map(items=>{
                  array.push({itemId:items.serveId,type:items.type,numericalValue:items.numericalValue,statisticalType:items.statisticalType})
              })
            })
            filterArray=array.filter(item=>{
              return item.type&&item.numericalValue
            })
            return filterArray
          }
          const cardFilter = (arr) => {
            let array = []
            let filterArray =[]
            arr.map(item=>{
              item.infos.map(items=>{
                  array.push({itemId:items.id,type:items.type,numericalValue:items.numericalValue})
              })
            })
            filterArray=array.filter(item=>{
              return item.type&&item.numericalValue
            })
            return filterArray
          }

          let sitArr = []
          if (ruleKind == 1) {
            sitArr = serveFilter(serveCategoryList.value)
          } else if (ruleKind == 2) {
            sitArr = cardFilter(serveCategoryList.value)
          }

          if (sitArr.length == 0) {
            ElMessage({
              message: '最少设置一个分类规则！',
              type: 'warning',
            })
            return
          }

          /*
            type
            1服务提成
            2卡项提成
          */

          let res
          // 判断是新增还是编辑 有ruleId是编辑 没有是新增
          // 新增
          if (!ruleId) {
            res = await createShopCommissionRule({ ...ruleForm,itemList:sitArr })
            if (res.code == 0) {
              success()
            }
          }
          // 编辑
          else {
            const res = await editShopCommissionRule({ ...ruleForm, id: route.query.ruleId,itemList:sitArr })
            if (res.code == 0) {
              success()
            }
          }
          console.log('submit!')
        } else {
          console.log('error submit!', fields)
        }
      })
    },1000)


    const rankDialogVisible = ref(false)
    const rankChangeDialogVisible = flag => {
      rankDialogVisible.value = flag
    }

    const handleGuanlianClick = () => {
      rankDialogVisible.value = true
    }


    const allShopBind = ref([])
    const shopList = ref([])
    const initPage = async () => {
      let ruleId = route.query.ruleId
      let type = route.query.ruleKind == 1?2:3
      const sres = await getUnBindRuleShopList(ruleId,type)
      shopList.value = sres.data

      let ruleType = route.query.ruleKind
      if (ruleType == 1) {
        const res = await getTeacherRankServeList({})
        serveCategoryList.value = res.data
      }else if (ruleType == 2) {
        const res = await getCardTreeList()
        serveCategoryList.value = res.data
      }

      // 判断是编辑还是新增 有ruleId是编辑，否则是新增
      // 编辑
      if (ruleId) {
        const detRes = await getShopCommissionRule(ruleId)
        let detail = detRes.data
        let { correlationIds, name, itemList} = detail
        ruleForm.name = name
        ruleForm.correlationIds = correlationIds
        if (route.query.ruleKind == 1) {
          serveCategoryList.value.map(item=>{
            item.infos.map(items=>{
              itemList.map(itemes=>{
                if (itemes.itemId==items.serveId) {
                  items.type=itemes.type
                  items.numericalValue=itemes.numericalValue
                  items.statisticalType=itemes.statisticalType
                }
              })
            })
          })
        }else{
          serveCategoryList.value.map(item=>{
            item.infos.map(items=>{
              itemList.map(itemes=>{
                if (itemes.itemId==items.id) {
                  items.type=itemes.type
                  items.numericalValue=itemes.numericalValue
                }
              })
            })
          })
        }
        serveCategoryList.value=serveCategoryList.value
      }
    }
    initPage()

    return {
      shopList,
      allShopBind,
      ruleFormRef,
      handleSaveClick,
      handleGuanlianClick,
      rankChangeDialogVisible,
      rankDialogVisible,
      ruleKind,
      ruleForm,
      rules,
      serveCategoryList,
      tichengType,
      RepurchaseType,
      handleTypeChange,
      handlePriceChange,
      editId,
      handleRepurchaseChange
    }

  }
})
</script>

<style scoped lang="less">
.base-form {
  position: relative;

  .mask {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  .opt-name {
    margin-left: 12px;
    font-size: 12px;

    &:deep(.el-tag) {
      margin-left: 10px;
    }
  }

  .jd-peizhi-value {
    max-width: 700px;

    .tips {
      color: #f56c6c;
      margin-top: 10px;
    }

    .titles {
      display: flex;
      height: 36px;
      line-height: 36px;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 14px;
      padding: 0 18px;

      .num {
        flex: 0.6;
      }

      .qujian {
        flex: 2;
      }

      .type {
        flex: 1.2;
      }

      .price {
        flex: 1.2;
      }

      .opt {
        flex: 0.8;
      }
    }
  }

  .peizhi-value {
    max-width: 700px;

    &:deep(.el-select) {
      max-width: 120px;
    }

    &:deep(.el-input) {
      max-width: 120px;
    }

    .peizhi-list {
      .tips {
        color: #f56c6c;
        font-size: 12px;
        margin-top: 10px;
      }

      .item {
        display: flex;
        line-height: 60px;
        height: 60px;
        border-bottom: 1px solid rgba(204, 204, 204, 0.6);
        padding: 0 18px;

        .num {
          flex: 0.6;
        }

        & > div {
          flex: 1;
        }

        .price {
          flex: 1.4;
        }
      }
    }

    .titles {
      display: flex;
      height: 36px;
      line-height: 36px;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 14px;
      padding: 0 18px;

      .num {
        flex: 0.6;
      }

      .price {
        flex: 1.4;
      }

      & > div {
        flex: 1;
      }
    }
  }

  .staff {
    display: flex;

    .opt {
      margin-left: 10px;
    }
  }

  .fa-name {
    &:deep(.el-input) {
      max-width: 260px;
    }
  }
}
</style>
