export function antiShake(fn, delay) {
    // 如果没传延迟时间，就默认200毫秒
    var delay = delay || 200
   
    // 记录上一次触发的定时器
    var timer = null
    return function () {
      const th = this
      var args = arguments
      // 如果定时器还没结束，再次触发了这个函数
      // 就清除这个定时器，重新计时
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(function () {
        timer = null
        // 定时器结束，正常执行函数
        fn.apply(th, args)
      }, delay)
    }
  }
   
  export function throttle(func, delay) {
	// 缓存一个定时器
	let timer = null
	// 这里返回的函数是每次用户实际调用的节流函数 
	return function(...args) {
		if (!timer) { //判断timer是否有值,如果没有则说明定时器不存在即可继续执行
			timer = setTimeout(() => { //关
				func.apply(this, arguments)
				timer = null; //开
			}, delay)
		}
	}
}