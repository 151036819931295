<template>
  <div class="add-rank-dialog">
    <page-dialog
      width="700px"
      v-if="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="特殊设置"
    >
      <div class="shop-list">
        <div class="titls">
          <div>服务名称</div>
          <div>售价</div>
          <div>提成类型</div>
          <div>提成金额</div>
        </div>
        <el-scrollbar height="300px">
          <template v-if="serveList.length > 0">
            <template :key="item" v-for="(item, index) in serveList">
              <div class="item">
                <div class="name">{{ item.serveName }}</div>
                <div>
                  <template v-if="item.paraList != null && item.paraList.length > 0">
                    ¥{{ (item.paraList[0]["salePrice"] / 100).toFixed(2) }}
                  </template>
                  <template v-else> - </template>
                </div>
                <div class="type">
                  <el-select
                    size="small"
                    v-model="ruleFormList[index].tctype"
                    class="m-2"
                    placeholder="提成类型"
                  >
                    <el-option
                      v-for="item in tichengType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
                <div class="price">
                  <el-input
                    size="small"
                    v-model="ruleFormList[index].tcprice"
                    placeholder="提成金额"
                  >
                    <template #suffix>
                      <template v-if="ruleFormList[index].tctype == 1"> | % </template>
                      <template v-else> | 元 </template>
                    </template>
                  </el-input>
                </div>
              </div>
            </template>
          </template>

          <template v-else>
            <el-empty description="暂无相关数据" />
          </template>
        </el-scrollbar>
      </div>

      <template v-if="total > 0">
        <div class="el-pagination">
          <el-pagination
            v-model:currentPage="ruleForm.page"
            v-model:page-size="ruleForm.pagesize"
            :page-sizes="[10, 20, 30, 40]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <div class="save">
          <el-button size="small" @click="handleSaveClick" color="#409EFF" type="primary"
            >保存</el-button
          >
        </div>
      </template>
      <template v-else>
        <div class="save">
          <el-button size="small" @click="handleCancelClick" plain>取消</el-button>
        </div>
      </template>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { react } from '@babel/types'
import { useState } from '@/hooks'
import { getServeList } from '@/service/main/commission'
import { tichengType } from '../../config'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    cid: {
      type: Number,
      default: -1
    },
    type: {
      type: Number,
      default: 1
    },
    tesuForm: {
      type: Object,
      required: true
    },
    oldSiteList: {
      type: Array,
      default() {
        return []
      }
    },
    openNum: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'changeRuleForm', 'handleSaveClick'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const ruleForm = reactive({
      companyId: COMPANY_ID,
      page: 1,
      pagesize: 10,
      servestatus: '1',
      keyword: '',
      kindid: ''
    })

    const total = ref(0)

    const handleSizeChange = (pagesize) => {
      ruleForm.pagesize = pagesize
      initPage()
    }

    const handleCancelClick = () => {
      changeDialogVisible(false)
    }

    const handleSaveClick = () => {
      emit('handleSaveClick')
    }

    const handleCurrentChange = page => {
      ruleForm.page = page
      initPage()
    }
    
    const ruleFormList = ref([])

    const initPage = async () => {
      ruleFormList.value = []
      let oldRuleList = props.oldSiteList
      console.log(oldRuleList);
      ruleForm.kindid = props.cid
      const res = await getServeList({ ...ruleForm })

      total.value = res.data.total
      let tesuFormList = props.tesuForm
      console.log(res.data.allServe);

      res.data.allServe.forEach(item => {
        let eles = tesuFormList.find(ele => {
          return ele.serveId == item.serveId
        })

        ruleFormList.value.push({
          serveId: item.serveId,
          tctype: eles != undefined ? eles.tctype : '',
          tcprice: eles != undefined ? eles.tcprice : '',
          cid: props.cid
        })
      })
      serveList.value = res.data.allServe

      let openNum=props.openNum
      if (oldRuleList.length > 0&&openNum<2) {
        setTimeout(() => {
          ruleFormList.value.forEach((item, index) => {
            console.log(item);
            let ele = oldRuleList.find(ele => ele.itemid == item.serveId)
            if (ele != undefined) {
              item['tcprice'] = ele.qty / 100
              item['tctype'] = String(ele.kind)
            }
          })
        }, 0);
      }

    }
    initPage()

    watch(ruleFormList, (n) => {
      console.log("bianhau========================");
      emit('changeRuleForm', n)
    }, {
      deep: true
    })


    const serveList = ref([])
    watch(() => props.cid, (n) => {
      serveList.value = []
      ruleForm.kindid = n
      // initPage()
    }, {
      deep: true,
    })



    return {
      handleCancelClick,
      handleSaveClick,
      changeDialogVisible,
      rankCategory,
      serveList,
      ruleForm,
      handleSizeChange,
      handleCurrentChange,
      ruleFormList,
      total,
      tichengType

    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  &:deep(.el-scrollbar__view) {
    font-size: 12px;
  }

  &:deep(.el-dialog) {
    min-width: 480px;
  }

  .save {
    text-align: right;
    margin-top: 8px;
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-dialog) {
    min-width: 460px;
  }

  &:deep(.el-dialog__body) {
    position: relative;
    top: -10px;
    padding-bottom: 10px !important;
  }

  .search {
    margin-bottom: 14px;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-scrollbar) {
    padding: 0 10px;
  }

  .pagination {
    margin-top: 10px;
    text-align: right;
  }

  .shop-list {
    .item {
      display: flex;
      max-width: 700px;
      line-height: 60px;
      height: 60px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.6);
      padding: 0 2px;

      .price {
        position: relative;
        left: 4px;
      }

      &:deep(.el-select) {
        max-width: 100px;
      }

      &:deep(.el-input) {
        max-width: 100px;
      }

      & > div {
        flex: 1;
      }
    }

    .titls {
      padding: 0 10px;
      display: flex;
      font-size: 12px;
      background-color: rgb(239, 239, 239);

      div {
        flex: 1;
        line-height: 40px;
      }
    }

    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          line-height: 60px;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
